import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  addChosenCycleToState,
  addChosenCycleToStateOtherCase,
  addChosenCycleToStateWhenSettedFirstDay,
  getCoopByIdThunk,
  removeLocalStorageDataOfCoopIdFromState,
  removeLocalStorageDataOfCycleIdFromState,
  removeLocalStorageDataOfFarmIdFromState,
  // removeLocalStorageDataOfGrothDayFromState,
  setActiveCycle,
  // setGrowthDayToCycle,
} from "../../../store/coopsForUserSlice";
// import CustomSelect from "../../shared-components/customSelect/CustomSelect";
import GrowthDayInput from "./growthDayInput/GrowthDayInput";
import style from "./style.module.scss";
import useIsDownloading from "../../../hooks/useIsDownloading";
import { addActiveWithinSessionFarm } from "../../../store/farmsForUserSlice";
// import {ReactComponent as Pen} from '../ ../../../assets/images/pen.svg';
import {ReactComponent as Pen} from '../../../../assets/images/pen.svg';
import { useRef } from "react";
import Select from "../../UI/Select/Select";
import TodayButton from "./TodayButton";
import ModalEdit from "./ModalEdit/ModalEdit";

function ProcessManagementBar() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [farmList, setFarmList] = useState([]);
  const [isShowModalEdit, setIsShowModalEdit] = useState(false);
  const farms = useSelector((state) => state.farmsForUser.getFarms.response);
  const activeCycleState = useSelector((state) => state.coopsForUser.activeCycle);
  
  useEffect(() => {
    if(farms) {
      setFarmList(() => {
        return farms.map((item) => {
          return {
            _id: item._id,
            name: item.name,
            coops: item.coops,
          };
        });
      });
    }
  }, [farms]);
  const activeWithinSessionFarm = useSelector(
    (state) => state.farmsForUser.chosenFarmWithinSession
  );
  const farmIdFromLocalStorage = useSelector(
    (state) => state.coopsForUser.farmIdFromLocalStorage
  );
  const coopIdFromLocalStorage = useSelector(
    (state) => state.coopsForUser.coopIdFromLocalStorage
  );
  const cycleIdFromLocalStorage = useSelector(
    (state) => state.coopsForUser.cycleIdFromLocalStorage
  );
  const growthDayFromLocalStorage = useSelector(
    (state) => state.coopsForUser.growthDayFromLocalStorage
  );

  const cyclesArray = useSelector((state) => state.coopsForUser.cyclesArray);
  const chosenCycle = useSelector((state) => state.coopsForUser.chosenCycle);
  const cycleWithSettedGrowthDay = useSelector(
    (state) => state.coopsForUser.cycleWithSettedGrowthDay
  );
  const [chosenFarm, setChosenFarm] = useState("");
  const chosenFarmRef = useRef(chosenFarm);

  const [chosenCoop, setChosenCoop] = useState("");
  const isDownloading = useIsDownloading();

  useEffect(() => {
    if (farms && farms.length > 0) {
      if (farmIdFromLocalStorage) {
        let targetFarm = farms.find(
          (farm) => farm._id === +farmIdFromLocalStorage
        );
        targetFarm
          ? handleSetChoosenFarm(targetFarm)
          : handleSetChoosenFarm(farms[0]);

        dispatch(removeLocalStorageDataOfFarmIdFromState(""));
      } else {
        if (activeWithinSessionFarm) {
          handleSetChoosenFarm(activeWithinSessionFarm);
        } else {
          handleSetChoosenFarm(farms[0]);
        }
      }
    }

    return () => {
      dispatch(addActiveWithinSessionFarm(chosenFarmRef.current));
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [farms]);

  useEffect(() => {
    if (chosenFarm?.coops?.length > 0) {
      if (coopIdFromLocalStorage) {
        let targetCoop = chosenFarm.coops.find(
          (coop) => coop._id === +coopIdFromLocalStorage
        );
        targetCoop
          ? handleSetChoosenCoop(targetCoop)
          : handleSetChoosenCoop(chosenFarm.coops[0]);

        dispatch(removeLocalStorageDataOfCoopIdFromState(""));
      } else {
        handleSetChoosenCoop(chosenFarm.coops[0]);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenFarm]);

  useEffect(() => {
    if (chosenCoop) {
      dispatch(getCoopByIdThunk({chosenCoopId: chosenCoop._id, cycleIdFromLocalStorage, growthDayFromLocalStorage}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenCoop]);

  useEffect(() => {
    if (!cyclesArray[0]?.growthDayUpdated) {
      console.log('first')
      if (cyclesArray.length) {
        const activeCycle = cyclesArray.find((cycle) => cycle.active);
        const savedCycle = cyclesArray.find(
          (cycle) => cycle._id === cycleIdFromLocalStorage
        );

        if (savedCycle) {
          dispatch(addChosenCycleToState(savedCycle));
          localStorage.setItem("SALUS_CYCLE_ID", savedCycle._id);

          if (savedCycle.active) {
            dispatch(setActiveCycle(savedCycle));
          }

          if (activeCycle !== savedCycle) {
            dispatch(setActiveCycle(activeCycle));
          }

          dispatch(removeLocalStorageDataOfCycleIdFromState(""));
          return;
        }

        if (activeCycle) {
          dispatch(addChosenCycleToState(activeCycle));
          dispatch(setActiveCycle(activeCycle));
          localStorage.setItem("SALUS_CYCLE_ID", activeCycle._id);
        } else {
          dispatch(addChosenCycleToState(cyclesArray.slice(-1)[0]));
          localStorage.setItem("SALUS_CYCLE_ID", cyclesArray.slice(-1)[0]._id);
          dispatch(setActiveCycle(""));
        }
      } else {
        dispatch(setActiveCycle(""));
        dispatch(addChosenCycleToState(""));
        localStorage.removeItem("SALUS_CYCLE_ID");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cyclesArray]);

  const handleSetChoosenFarm = (farm) => {
    setChosenFarm(farm);
    chosenFarmRef.current = farm;
    dispatch(addActiveWithinSessionFarm(farm));
    localStorage.setItem("SALUS_FARM_ID", farm._id);
  };

  const handleSetChoosenCoop = (coop) => {
    setChosenCoop(coop);
    localStorage.setItem("SALUS_COOP_ID", coop._id);
  };

  const handleSetChoosenCycle = (chosenCycle) => {
    localStorage.setItem("SALUS_CYCLE_ID", chosenCycle._id);
    dispatch(
      cycleWithSettedGrowthDay
        ? addChosenCycleToStateWhenSettedFirstDay({
            chosenCycleId: chosenCycle._id,
            cyclesArray,
            cycleWithSettedGrowthDay,
          })
        : addChosenCycleToStateOtherCase({
            chosenCycleId: chosenCycle._id,
            cyclesArray,
          })
    );
  };

  return (
    <div className={style.selections}>
      <div className={style.selectionItem}>
        <span className={style.selectionItemName} >{t("HEADER.FARM_NAME")}</span>
        <Select
          list={farmList}
          activeSelect={chosenFarm}
          handleSelect={handleSetChoosenFarm}
        />
      </div>

      <div className={style.selectionItem}>
        <span className={style.selectionItemName}>{t("HEADER.COOP_NAME")}</span>
        <Select
          list={chosenFarm?.coops?.length > 0 ? chosenFarm?.coops : []}
          activeSelect={chosenCoop}
          handleSelect={handleSetChoosenCoop}
        />
      </div>

      <div className={style.seporator} />

      <div className={style.selectionItem}>
        <span className={style.selectionItemName}>{t("HEADER.CYCLE")}</span>
        <Select
          list={cyclesArray.length > 0 ? cyclesArray : []}
          activeSelect={chosenCycle}
          handleSelect={handleSetChoosenCycle}
        />
      </div>
      
      <div className={style.selectionItem}>
        <span className={style.selectionItemName}>{t("HEADER.GROWTH_DAY")}</span>
        <GrowthDayInput isDownloading={isDownloading} />

        {activeCycleState && activeCycleState?._id === chosenCycle && chosenCycle?._id  && <div 
          onClick={()=>setIsShowModalEdit(true)} 
          className={style.editButton}
        ><Pen /></div>}
      </div>

      {(chosenCycle.maxGrowthDay !== chosenCycle.settedGrowthDay && 
      <div className={style.selectionItem}>
        <TodayButton />
      </div>) || ''} 
      <ModalEdit isShow={isShowModalEdit} setShow={setIsShowModalEdit}/>
    </div>
  );
}

export default ProcessManagementBar;
