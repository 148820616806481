import { combineReducers, configureStore } from "@reduxjs/toolkit";
import usersDataSlice from "./adminSlices/usersDataSlice";
import farmsDataSlice from "./adminSlices/farmsDataSlice";
import authSlice from "./authSlice";
import farmsForUserSlice from "./farmsForUserSlice";
import coopsForUserSlice from "./coopsForUserSlice";
import alertsForUserSlice from "./dashboardSlices/alertsForUserSlice";
import deathRateForUserSlice from "./dashboardSlices/deathRateForUserSlice";
import growthRateForUserSlice from "./dashboardSlices/growthRateForUserSlice";
import sensorsStatusForUserSlice from "./dashboardSlices/sensorsStatusForUserSlice";
import ventilationDataForUserSlice from "./dashboardSlices/ventilationSlices/ventilationDataForUserSlice";
import tempAndHumidForUserSlice from "./dashboardSlices/ventilationSlices/tempAndHumidForUserSlice";
import averageDataForUserSlice from "./dashboardSlices/ventilationSlices/averageDataForUserSlice";
import AQIForUserSlice from "./dashboardSlices/efficiencySlices/AQIForUserSlice";
import PHIForUserSlice from "./dashboardSlices/efficiencySlices/PHIForUserSlice";
import moisterRemovalForUserSlice from "./dashboardSlices/ventilationSlices/moisterRemovalForUserSlice";
import alertsDataSlice from "./adminSlices/alertsDataSlice";
import coopsDataSlice from "./adminSlices/coopsDataSlice";
import updatingSlice from "./updatingSlice";
import reloadStatus from "./reloadMarker";

const rootReducer = combineReducers({
  auth: authSlice,
  alertsData: alertsDataSlice,
  farmsForUser: farmsForUserSlice,
  coopsForUser: coopsForUserSlice,
  alertsForUser: alertsForUserSlice,
  deathRateForUser: deathRateForUserSlice,
  growthRateForUser: growthRateForUserSlice,
  sensorsStatusForUser: sensorsStatusForUserSlice,
  ventilationDataForUser: ventilationDataForUserSlice,
  tempAndHumidForUser: tempAndHumidForUserSlice,
  averageDataForUser: averageDataForUserSlice,
  AQIForUser: AQIForUserSlice,
  PHIForUser: PHIForUserSlice,
  moisterRemovalForUser: moisterRemovalForUserSlice,
  usersData: usersDataSlice,
  coopsData: coopsDataSlice,
  farmsData: farmsDataSlice,
  updating: updatingSlice,
  reload: reloadStatus
});

export const store = configureStore({
  reducer: rootReducer,
});
