function MoistIcon({ isTemp }) {
  return (
    <svg
      id="icon-moist-sml"
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="24"
      viewBox="0 0 19 24"
    >
      <path
        id="Path_161"
        data-name="Path 161"
        d="M-361.476-6131.391c0-4.736-5.3-6.6-6.152-11.168-.856,4.57-6.15,6.433-6.15,11.168a6.241,6.241,0,0,0,6.15,6.013A6.241,6.241,0,0,0-361.476-6131.391Zm-13.434-1.265a3.178,3.178,0,0,1-1.823-3.024c0-1.39.8-2.366,1.732-3.495a17.251,17.251,0,0,0,1.221-1.616,16.791,16.791,0,0,0,1.223,1.615c.222.27.43.524.629.785l1.425-1.484c-.221-.288-.287-.3-.51-.572a14.119,14.119,0,0,1-2.767-4.426,12.043,12.043,0,0,1-2.765,4.428c-1.026,1.246-2.188,2.659-2.188,4.765a5.164,5.164,0,0,0,.931,2.941,7.044,7.044,0,0,0,2.619,2.3Z"
        transform="translate(379.597 6146.249)"
        fill={isTemp ? "#414141" : "white"}
      />
      <g
        id="Rectangle_261"
        data-name="Rectangle 261"
        transform="translate(0)"
        fill="none"
        stroke="#fff"
        strokeWidth="1"
        opacity="0"
      >
        <rect width="19" height="24" stroke="none" />
        <rect x="0.5" y="0.5" width="18" height="23" fill="none" />
      </g>
    </svg>
  );
}

export default MoistIcon;
