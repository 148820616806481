import styles from "./LoginPage.module.scss";
import mainImage from "../../../assets/images/LogIn-Farm.png";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import CommonInput from "../../components/shared-components/commonInput/CommonInput";
import "../../../i18n";
import PasswordInput from "../../components/shared-components/passwordInput/PasswordInput";
import CommonButton from "../../components/shared-components/commonButton/CommonButton";
import { loginUserThunk } from "../../store/authSlice";
import { useDispatch } from "react-redux";
import {ReactComponent as Logo} from '../../../assets/images/logo-v2.svg';


function LoginPage() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const defaultValues = {
    username: "",
    password: "",
  };

  const { control, handleSubmit } = useForm({
    defaultValues,
  });

  const onSubmit = (data) => {
    dispatch(loginUserThunk(data));
  };

  return (
    <div className={styles.container}>
      <div className={styles.formPart}>
        <form className={styles.loginForm} onSubmit={handleSubmit(onSubmit)}>
          <h2>{t("LOGINPAGE.WELCOME")}</h2>

          <div className={styles.inputs}>
            <div className={styles.userNameInputContainer}>
              <CommonInput
                labelText={t("LOGINPAGE.USERNAME")}
                placeholder={t("LOGINPAGE.USERNAME")}
                control={control}
                name="username"
              />
            </div>

            <PasswordInput
              labelText={t("LOGINPAGE.PASSWORD")}
              placeholder={t("LOGINPAGE.PASSWORD")}
              control={control}
              name="password"
            />
          </div>

          <div className={styles.buttons}>
            <CommonButton
              buttonText={t("LOGINPAGE.LOG_IN")}
              buttonType="submit"
              buttonStyles={{
                backgroundColor: "#FFB23A",
              }}
            />
          </div>
        </form>
      </div>
      <div className={styles.imagePart}>
        <div
          className={classNames(styles.helperBlock, {
            [styles.helperBlockRTL]: i18n.language === "he",
          })}
        >
          <Logo height="120" />
        </div>

        <img
          src={mainImage}
          alt="mainImage"
          className={i18n.language === "he" ? styles.imgLTR : null}
        />
      </div>
    </div>
  );
}
export default LoginPage;
