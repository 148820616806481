import styles from "./Data.module.scss";

function Data() {
 
  return (
    <div className={styles.container}>
      <div className={styles.columnsContainer}>
        
      </div>
    </div>
  );
}

export default Data;
