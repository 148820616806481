import { useEffect, useState } from "react";
import styles from "./TemperatureHumidityData.module.scss";
import classNames from "classnames";
import AverageData from "./averageData/AverageData";
import { useTranslation } from "react-i18next";
import AverageDataBySensor from "./averageData/AverageDataBySensor";
import { api } from "../../../api/api";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";

function TemperatureHumidityData() {
  const { t } = useTranslation();
  const [isAverage, setIsAverage] = useState(true);
  const [data, setData] = useState();
  const chosenCycle = useSelector((state) => state.coopsForUser.chosenCycle);

  useEffect(() => {
    makeRequest();
  }, [chosenCycle]);

  const makeRequest = () => {
    if(!chosenCycle._id) return;
    api.dashboard.getAverageData(chosenCycle._id, chosenCycle.settedGrowthDay).then((data) => {
      setData(data);
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.componentHeader}>
        <h3>{t("VENTILATION.CLIMATE")}</h3>

        <div className={styles.headerButtons}>
          <button
            type="button"
            onClick={() => setIsAverage(true)}
            className={classNames(styles.headerButton, {
              [styles.pressedButton]: isAverage,
              [styles.unpressedButton]: !isAverage,
            })}
          >
            {t("VENTILATION.AVERAGE_BUTTON_2")}
          </button>
          <button
            type="button"
            onClick={() => setIsAverage(false)}
            className={classNames(styles.headerButton, {
              [styles.pressedButton]: !isAverage,
              [styles.unpressedButton]: isAverage,
            })}
          >
            {t("VENTILATION.SENSORS_BUTTON")}
          </button>
        </div>

      </div>
      {isAverage && <AverageData data={data}/>}
      {!isAverage && <AverageDataBySensor data={data}/>}
    </div>
  );
}

export default TemperatureHumidityData;
