import { createSlice } from "@reduxjs/toolkit";

// ['started', 'finished']
const initialState = {
  reloadStatus: "finish"
};

const reloadStatus = createSlice({
  name: "updating",
  initialState,
  reducers: {
    changeUpdatingReloadStatus(state, action) {
      state.reloadStatus = action.payload;
    },
  },
});

export const {
  changeUpdatingReloadStatus,
} = reloadStatus.actions;
export default reloadStatus.reducer;
