import { makeRequest } from "../makeRequest";

const URL = "/Farms";

export const getFarms = () => {
  return makeRequest({
    method: "GET",
    url: URL,
  });
};

export const getFarmList = async () => {
  const res = await makeRequest({
    method: "GET",
    url: `${URL}/listInfo`,
  });

  if(res.data.status === 'ok') {
    return res.data.data;
  }
};
