import { useEffect, useState } from "react";
import LineChart from "../lineChart/LineChart";
import styles from "./AverageData.module.scss";
import classNames from "classnames";
import TemperatureIcon from "../icons/TemperatureIcon";
import MoistIcon from "../icons/MoistIcon";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

function TemperatureHumidityData({data}) {
  const { t } = useTranslation();
  const chosenCycle = useSelector((state) => state.coopsForUser.chosenCycle);
  
  const [humidData, setHumidData] = useState(null);
  const [tempData, setTempData] = useState(null);
  const [averageData, setAverageData] = useState(null);
  const [samples, setSamples] = useState(null);
  const [loader, setLoader] = useState(true);

  const [isTemp, setIsTemp] = useState(true);



  const tempGridValues = [5, 10, 15, 20, 25, 30, 35, 40];
  const humidGridValues = [10, 20, 30, 40, 50, 60, 70, 80, 90];

  const defineGridLines = (data, gridValues) => {
    if(!data) return;
    const collorsArray = gridValues.map((item) => {
      return item <= data.dangerLow || item >= data.dangerHigh
        ? "#F24E1E" /* red */
        : (item > data.dangerLow && item <= data.poorLow) ||
          (item >= data.poorHigh && item < data.dangerHigh)
        ? "#FFB23A" /* yellow */
        : item > data.poorLow && item < data.poorHigh
        ? "#0ACF83" /* green */
        : "grey";
    });
    return collorsArray;
  };
  
  useEffect(() => {
    if(!data) return;
      makeRequest(data);
  }, [data]);

  const makeRequest = (data) => {
    if(!chosenCycle._id) return;
      setAverageData(data.averageData);
      setSamples(data.samples);
      if(data.averageData) {
        setTempData(
          {
            dangerHigh: data.averageData.tempDangerHigh,
            dangerLow: data.averageData.tempDangerLow,
            max: data.averageData.tempMax,
            min: data.averageData.tempMin,
            maxTime: data.averageData.tempMaxTime,
            minTime: data.averageData.tempMinTime,
            now: data.averageData.tempNow,
            poorHigh: data.averageData.tempPoorHigh,
            poorLow: data.averageData.tempPoorLow,
            maxTempSample: data.averageData.maxTempSample,
            minTempSample: data.averageData.minTempSample,
          }
        );
        setHumidData(
          {
            dangerHigh: data.averageData.humidDangerHigh,
            dangerLow: data.averageData.humidDangerLow,
            max: data.averageData.humidMax,
            min: data.averageData.humidMin,
            maxTime: data.averageData.humidMaxTime,
            minTime: data.averageData.humidMinTime,
            now: data.averageData.humidNow,
            poorHigh: data.averageData.humidPoorHigh,
            poorLow: data.averageData.humidPoorLow,
            maxHumidSample: data.averageData.maxHumidSample,
            minHumidSample: data.averageData.minHumidSample,
          }
        )
      }
      console.log("data.averageData - ", data.averageData)
      setLoader(false);
  };

  return (
        <div className={styles.componentContentBySensor}>
          <div className={styles.lineChart}>
            {data && tempData && humidData && (
              <LineChart
              dataSensors={isTemp ? data.samples.averageTempBySensor : data.samples.averageHumidBySensor}
              safetyRange={isTemp ? tempData : humidData}
              minY={isTemp ? 5 : 10}
              maxY={isTemp ? 40 : 90}
              splitNumber={isTemp ? 7 : 8}
              markOfValue={isTemp ? "°" : ""}
              gridLines={
                isTemp
                  ? defineGridLines(tempData, tempGridValues)
                  : defineGridLines(humidData, humidGridValues)
              }
            />
            )}
                
          </div>
          <div className={styles.secondPart}>
            <div className={styles.buttons}>
              <button
                type="button"
                onClick={() => setIsTemp(true)}
                className={classNames({
                  [styles.pressedButton]: isTemp,
                  [styles.unpressedButton]: !isTemp,
                })}
              >
                <TemperatureIcon isTemp={isTemp} />
                {t("VENTILATION.TEMP_BUTTON")}
              </button>
              <button
                type="button"
                onClick={() => setIsTemp(false)}
                className={classNames({
                  [styles.pressedButton]: !isTemp,
                  [styles.unpressedButton]: isTemp,
                })}
              >
                <MoistIcon isTemp={isTemp} />
                {t("VENTILATION.RH_BUTTON")}
              </button>
            </div>
          </div>
        </div>
  );
}

export default TemperatureHumidityData;
