import ReactEChart from "echarts-for-react";
import { useTranslation } from "react-i18next";
import moment from "moment";
const curveColors = [
  "4DB6AC",
  "00796B",
  "D684E3",
  "8F66E6",
  "809DDF",
  "425BFE",
];

function LineChart({
  dataSensors,
  safetyRange,
  minY,
  maxY,
  splitNumber,
  markOfValue,
  gridLines,
}) {
  const { t } = useTranslation();
  const isNoData = (dataSensors) =>
    Object.keys(dataSensors).some((key) => dataSensors[key].length > 0);

  const eChartsOption = {
    tooltip: {
      trigger: "axis",
      formatter: function (params) {
        let colorSpan = (color) =>
          '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:' +
          color +
          '"></span>';
        let rez = "<p>" + moment(params[0].axisValueLabel).format("YYYY-MM-DD HH:mm:ss") + "</p>";
        params.forEach((item, index) => {
          let xx =
            '<p style="display:flex; justify-content:space-between">' +
            "<span>" +
            colorSpan(`#${curveColors[index]}`) +
            " " +
            item.seriesName +
            " " +
            "</span>" +
            "<span>" +
            "<strong>" +
            item.data.toFixed(2) +
            "</strong>" +
            "</span>" +
            "</p>";
          rez += xx;
        });

        return rez;
      },
    },
    grid: {
      left: "3",
      right: "17",
      bottom: isNoData ? "15" : "0",
      top: "15",
      containLabel: true,
    },
    xAxis: [
      {
      type : 'category',
      splitLine: {
        show: true,
      },
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        color: "#181718",
        fontFamily: "Heebo-Regular",
        fontSize: "0.88rem",
        margin: 15,
        formatter: (function(value){
          return moment(value).format('HH:mm');
      }),
      },
      data: (function (){
        return dataSensors[0].map(i => i[0])
      })(),
      boundaryGap: false
    },
    {
      type : 'category',
    },
    {
      type : 'category',
    },
  ],
    yAxis: [{
      type: "value",
      min: minY,
      max: maxY,
      splitNumber: splitNumber,
      axisLabel: {
        formatter: `{value}${markOfValue}`,
        fontFamily: "Heebo-Medium",
        fontSize: "0.88rem",
        
        textStyle: {
          color: function (value) {
            return value >= safetyRange["dangerHigh"] ||
              value <= safetyRange["dangerLow"]
              ? "#F24E1E"
              : (value >= safetyRange["poorHigh"] &&
                  value < safetyRange["dangerHigh"]) ||
                (value <= safetyRange["poorLow"] &&
                  value > safetyRange["dangerLow"])
              ? "#FFB23A"
              : "#0ACF83";
          },
        },
      },
      splitLine: {
        lineStyle: {
          color: gridLines,
          width: 1,
        },
      },
    },
    {
      type: "value",
    },
    {
      type: "value",
    }
  ],
    legend: {
      data:['Area A','Area B','Area C']
    },
    series: [
      {
        name: `${t("CHART.ARIA")} A`,
        type: "line",
        lineStyle: {
          color: `#${curveColors[0]}`,
        },
        symbol: `image://data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' height='12' width='12'%3E%3Ccircle cx='6' cy='6' r='4' stroke='%23${curveColors[0]}'  stroke-width='3' fill='white'/%3E%3C/svg%3E`,
        symbolSize: 1,
        data: dataSensors[0].map(i => i[1]),
        smooth: true,
      },
      {
        name: `${t("CHART.ARIA")} B`,
        type: "line",
        lineStyle: {
          color: `#${curveColors[1]}`,
        },
        symbol: `image://data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' height='12' width='12'%3E%3Ccircle cx='6' cy='6' r='4' stroke='%23${curveColors[1]}'  stroke-width='3' fill='white'/%3E%3C/svg%3E`,
        symbolSize: 1,
        data: dataSensors[1].map(i => i[1]),
        smooth: true,
      },
      {
        name: `${t("CHART.ARIA")} C`,
        type: "line",
        lineStyle: {
          color: `#${curveColors[2]}`,
        },
        symbol: `image://data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' height='12' width='12'%3E%3Ccircle cx='6' cy='6' r='4' stroke='%23${curveColors[2]}' stroke-width='3' fill='white'/%3E%3C/svg%3E`,
        symbolSize: 1,
        data: dataSensors[2].map(i => i[1]),
        smooth: true,
      }
    ],
  };

  return (
    <ReactEChart
      option={eChartsOption}
      style={{ height: "100%", width: "100%" }}
    />
  );
}

export default LineChart;
