import { useSelector } from "react-redux";
import { api } from "../../../api/api";
import styles from "./WindSoundFeels.module.scss";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import classNames from "classnames";
// import { useTranslation } from "react-i18next";


function WindSoundFeels() {
  // const { i18n, t } = useTranslation();
  const { t } = useTranslation();
  const [loader, setLoader] = useState(true);
  const [loaderSound, setLoaderSound] = useState(true);
  const [data, setData] = useState({});
  const [sound, setSound] = useState({});
  const chosenCycle = useSelector((state) => state.coopsForUser.chosenCycle);

  useEffect(() => {
    makeRequest();
  }, [chosenCycle]);

  const makeRequest = () => {
    if (!chosenCycle._id) return;
    setLoader(true);
    api.dashboard.getWindAndFeels(chosenCycle._id, chosenCycle.settedGrowthDay).then((data) => {
      setData(data);
      setLoader(false);
    });
    api.dashboard.getSound(chosenCycle._id, chosenCycle.settedGrowthDay).then((data) => {
      setSound(data);
      setLoaderSound(false);
    });
  };

  return (
    <div className={styles.container}>

      <div className={styles.sound}>
        <div className={styles.title}>Sound (24hr)</div>
        <div className={styles.content}>
        {loaderSound ? (
          <div className={styles.spinnerContainer}>
            <CircularProgress />
          </div>
        ) : (<>
          <div className={styles.block}>
            <div className={styles.title}>Average</div>
            <div className={classNames(styles.value, styles.pluss)}><span>{sound.sound}</span>db/h</div>
          </div>

          <div className={styles.seporate}></div>

          <div className={styles.block}>
            <div className={styles.title}>Average Change</div>
            <div className={classNames(styles.value, {
              [styles.minus]: sound.averageSound < 0,
              [styles.pluss]: sound.averageSound > 0,
            })}><span>{sound.averageSound}%</span>db/h</div>
          </div>
          </>)}
        </div>
      </div>

      <div className={styles.windFeels}>
        {loader ? (
          <div className={styles.spinnerContainer}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <div className={styles.content}>
              <div className={styles.title}>Wind Speed</div>
              <div className={styles.block}>
                <div className={styles.value}><span>{data.wind}</span>m/s</div>
              </div>
            </div>

            <div className={styles.seporate}></div>

            <div className={styles.content}>
              <div className={styles.title}>Feels Like</div>
              <div className={styles.block}>
                <div className={styles.value}><span>{data.feels}</span></div>
              </div>

            </div>
          </>
        )}
      </div>

    </div>
  );
}

export default WindSoundFeels;
