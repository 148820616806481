function TemperatureIcon({ isTemp }) {
  return (
    <svg
      id="icon-temp-sml"
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="24"
      viewBox="0 0 19 24"
    >
      <path
        id="Path_160"
        data-name="Path 160"
        d="M-310.643-6089.942a4.153,4.153,0,0,0-2.079-3.6v-13.252a2.08,2.08,0,0,0-2.08-2.079,2.079,2.079,0,0,0-2.079,2.079v13.252a4.153,4.153,0,0,0-2.079,3.6,4.158,4.158,0,0,0,4.158,4.158A4.16,4.16,0,0,0-310.643-6089.942Zm-8.185-5.009v-2h-2.905v2Zm0-4.356v-2h-2.905v2Zm0-4.358v-2h-2.905v2Z"
        transform="translate(325.135 6109.744)"
        fill={isTemp ? "#fff" : "#414141"}
      />
      <g
        id="Rectangle_260"
        data-name="Rectangle 260"
        transform="translate(0)"
        fill="none"
        stroke="#fff"
        strokeWidth="1"
        opacity="0"
      >
        <rect width="19" height="24" stroke="none" />
        <rect x="0.5" y="0.5" width="18" height="23" fill="none" />
      </g>
    </svg>
  );
}

export default TemperatureIcon;
