import { useController } from "react-hook-form";
import styles from "./CommonInput.module.scss";

function CommonInput({
  labelText,
  isErrorShown = false,
  isErrorShownAlways = false,
  placeholder,
  control,
  name,
  rules,
  inputType = "text",
  setValue,
  value,
  isAutoFocus,
}) {
  const {
    field,
    fieldState: { error, isTouched },
  } = useController({ control, name, rules, value });

  const onChangeNumber = (data) => {
    if (/^[0-9]+$/.test(data.target.value) || data.target.value === "") {
      setValue(field.name, Number(data.target.value));
    }
  };

  const conditionForShowingError = isErrorShownAlways
    ? error && isErrorShown
    : error && isErrorShown && isTouched;

  
  return (
    <label className={styles.inputLabel}>
      <span>{labelText}</span>
      <input
        autoFocus={isAutoFocus}
        {...field}
        type={inputType}
        placeholder={placeholder}
        onChange={inputType === "number" ? onChangeNumber : field.onChange}
      />
      {conditionForShowingError && (
        <p className={styles.errorMessage}>{error.message}</p>
      )}
    </label>
  );
}

export default CommonInput;
