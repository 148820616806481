import { Route, Routes } from "react-router-dom";
import LoginPage from "../pages/loginPage/LoginPage";
import MainPage from "../pages/mainPage/MainPage";
import ManageAlertsPage from "../pages/manageAlertsPage/manageAlertsPage";
import ManageUsersPage from "../pages/manageUsersPage/ManageUsersPage";
import ManageFarmsPage from "../pages/manageFarmsPage/manageFarmsPage";
import UpdateFarmPage from "../pages/updateFarmPage/updateFarmPage";
import UpdateCoopPage from "../pages/updateCoopPage/updateCoopPage";
import LayoutDefault from "../layout/LayoutDefault";
import DashboardHeader from "../components/header/pages/dashboard/DahshboardHeader";
import Data from "../pages/data/Data";
import Calculator from "../pages/calculator/Calculator";
import Report from "../pages/report/Report";
import TableCoops from "../pages/report/tableCoops/TableCoops";
/* import { getProfile } from "../../store/authActions"; */

const appRoutes = [
  {
    id: 1,
    path: "/",
    element: <LoginPage />,
    availableFor: [null, "user", "admin"],
  },
  {
    id: 2,
    path: "/dashboard",
    element: <MainPage />,
    headerBar: <DashboardHeader />,
    availableFor: ["user", "admin"],
  },
  {
    id: 3,
    path: "/data",
    element: <Data />,
    headerBar: <DashboardHeader />,
    availableFor: ["user", "admin"],
  },
  {
    id: 4,
    path: "/calculator",
    element: <Calculator />,
    headerBar: <DashboardHeader />,
    availableFor: ["user", "admin"],
  },
  
  {
    id: 5,
    path: "/report",
    element: <Report />,
    headerBar: <DashboardHeader />,
    availableFor: ["user", "admin"],
  },

  {
    id: 6,
    path: "/report/:id/coops",
    element: <TableCoops/>,
    headerBar: <DashboardHeader />,
    availableFor: ["user", "admin"],
  }
  // {
  //   id: 3,
  //   path: "/manage-farms",
  //   element: <ManageFarmsPage />,
  //   availableFor: ["admin"],
  // },
  // {
  //   id: 4,
  //   path: "/update-farm",
  //   element: <UpdateFarmPage />,
  //   availableFor: ["admin"],
  // },
  // {
  //   id: 5,
  //   path: "/update-coop",
  //   element: <UpdateCoopPage />,
  //   availableFor: ["admin"],
  // },
  // {
  //   id: 6,
  //   path: "/manage-alerts",
  //   element: <ManageAlertsPage />,
  //   availableFor: ["admin"],
  // },
  // {
  //   id: 7,
  //   path: "/manage-users",
  //   element: <ManageUsersPage />,
  //   availableFor: ["admin"],
  // },
];

function AppRoutes() {
  /*   const dispatch = useDispatch(); */
  /*   const isUserAuth = useSelector((state) => state.auth.authData.accessToken);
  const userRole  = useSelector(state => state.auth.profileData.profile.role) */
  /*   const navigate = useNavigate(); */

  /* useEffect( () => {
  if(!isUserAuth) {
    navigate('/')
  }
}, [isUserAuth, navigate])



useEffect( () => {
  if(isUserAuth) {
    dispatch(getProfile())
  } 
}, [isUserAuth, dispatch]) */

  const availableRoutes = () =>
    appRoutes.filter(
      (route) =>
        route.availableFor.includes(null) ||
        route.availableFor.includes("admin")
    );

  return (
    <>
      <Routes>
        {availableRoutes().map((route) => (
          route.path === '/' ? 
            <Route key={route.id} path={route.path} element={route.element} />:
            <Route key={route.id} path={route.path} element={<LayoutDefault component={route.element} headerBar={route.headerBar} />} />
        ))}
      </Routes>
    </>
  );
}

export default AppRoutes;
